import * as React from 'react'
import { graphql } from 'gatsby'

import { withLayout } from 'components/Layout'
import { Feed, FeedPageProps } from 'components/pages/blog/Feed/Feed'

/**
 * Mediator between a real page component and Gatsby conventions:
 * decoupled for the tests sake
 * (currently graphql is not available in Storybook)
 */
const blog = (props: FeedPageProps) => <Feed {...props}/>

export const query = graphql`
{
  site {
    siteMetadata {
      title
      description
      logoTitle
      logoSecondaryTitle
      logoImageAlt
    }
  }
  posts: allMarkdownRemark(
    filter: { fileAbsolutePath: { regex: "^/content/blog/.+$/" } },
    sort: { fields: frontmatter___date, order: DESC }
  ) {
    edges {
      node {
        frontmatter {
          slug
          title
          dateRaw: date
          date(formatString: "MMMM DD, YYYY")
          description
          keywords
          author
          excerpt
          image {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        html
      }
    }
  }
}
`

export default withLayout(blog)
