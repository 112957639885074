import * as React from 'react'
import { Container } from 'react-bootstrap'
// tslint:disable-next-line:import-name
import Img from 'gatsby-image'

import WebsiteConfig from 'WebsiteConfig'

import Seo from 'components/shared/Seo/Seo'
import getWebPageCombined from 'shared/util/schema/getWebPageCombined'
import PageHeader from 'components/shared/PageHeader/PageHeader'
import PageSection from 'components/shared/PageSection/PageSection'
import PageFooter from 'components/shared/PageFooter/PageFooter'
import JsonLd from 'components/scripts/JsonLd/JsonLd'
import { PostContent } from 'components/pages/blog/Post/Post'

import './Feed.scss'
import { Link } from 'gatsby'

export type FeedPageProps = {
  websiteConfig: WebsiteConfig
  location: {
    pathname: string
  }
  data: {
    site: {
      siteMetadata: any
    }
    posts: PostContentEdges
  }
}

type PostContentEdges = { edges: { node: PostContent }[] }

// todo add Blog json+ld
// todo add Test
// todo add pagination
export class Feed extends React.Component<FeedPageProps> {
  renderPostTeaser({ node: post }: { node: PostContent }) {
    return (
      <article className="Feed__post text-left" key={post.frontmatter.title}>
        <header className="mb-3">
          <h1 className="Feed__postTitle mb-2 mt-3">{post.frontmatter.title}</h1>
          <time
            className="Feed__postDate"
            dateTime={post.frontmatter.date}
          >
            {post.frontmatter.date}
          </time>
        </header>
        <figure className="Feed__postImageContainer text-center">
          <Img
            fluid={post.frontmatter.image.childImageSharp.fluid}
            className="Feed__postImage"
            alt={post.frontmatter.title}
          />
        </figure>
        <div
          className="Feed__postBody text-left"
          dangerouslySetInnerHTML={{ __html: post.frontmatter.excerpt }}
        />
        <Link to={post.frontmatter.slug} className="Feed__postLink">
          Read more →
        </Link>
      </article>
    )
  }

  render(): React.ReactNode {
    const { data: { posts: { edges: postNodes } } } = this.props
    const {
      menuItems,
      footer,
      schema,
      blog: {
        feedTitle,
        feedDescription,
      },
    } = this.props.websiteConfig

    return (
      <>
        <Seo
          title={feedTitle}
          description={feedDescription}
          websiteConfig={this.props.websiteConfig}
        />
        <JsonLd>{getWebPageCombined(schema)}</JsonLd>
        <PageHeader
          pathname={this.props.location.pathname}
          items={menuItems}
          activeItem="#blog"
          rewriteActive="/blog"
          texts={{
            ...this.props.data.site.siteMetadata,
          }}
        />
        <PageSection id="BlogPostsFeed" className="Feed d-md-flex">
          <Container>
            <article
              className="
                Feed__container
                d-flex flex-column
                align-items-center
                justify-content-center
                u-full-height
                text-center
              "
            >
              <div className="Feed_posts">
                {postNodes.map(this.renderPostTeaser)}
              </div>
            </article>
          </Container>
        </PageSection>
        <PageFooter {...footer}/>
      </>
    )
  }
}
